import {
    COMPANIES_FEED,
    HOME,
    JOBS_FEED,
    NEW_COMPANY,
    NEW_JOB,
    VIEW_JOB,
    UPDATE_COMPANY_PROFILE,
    VIEW_COMPANY_PROFILE,
    UPDATE_JOB_POST,
    LIST_OF_JOBS_PER_COMPANY,
    MY_JOBS,
    APPROVAL
} from "../../constants/routes";
import React from "react";
import { Route, Routes } from "react-router-dom";
import JobList from "../Feed/jobs/JobList";
import JobForm from "../Feed/jobs/CreateJobsPostForm";
import MoreJobDetails from "../Feed/jobs/MoreJobDetails";
import UpdateJobForm from "../Feed/jobs/UpdateJobForm";
import ListOfJobsPerCompany from "../Feed/jobs/ListOfJobsPerCompany";

import CompanyProfileList from "../Feed/company/CompanyProfileList";
import CompanyForm from "../Feed/company/CreateCompanyProfiletForm";
import UpdateCompanyProfile from "../Feed/company/UpdateCompanyProfile";
import ViewIndividualCompany from "../Feed/company/individualProfile/ViewIndividualCompany";
import Approval from "../Feed/company/individualProfile/Approval";

import UserJobsList from "../User/UserJobs";

const CustomRoutes = ({ currentJobPage }) => {
    return (
        <Routes>
            {/* Home Route */}
            <Route exact path={ HOME } element={<JobList currentPage={currentJobPage} />}/>

            {/* Feed Routes */}
            <Route exact path={ JOBS_FEED } element={<JobList currentPage={currentJobPage} />}/>
            <Route exact path={ COMPANIES_FEED } element={<CompanyProfileList />}/>

            {/* Create Routes */}
            <Route path={ NEW_COMPANY } element={<CompanyForm />} />
            <Route path={ NEW_JOB } element={<JobForm />} />

            {/* View Routes */}
            <Route path={ VIEW_JOB } element={<MoreJobDetails />}/>
            <Route path={ VIEW_COMPANY_PROFILE } element={<ViewIndividualCompany />} />

            {/* Update Forms */}
            <Route path={ UPDATE_COMPANY_PROFILE } element={<UpdateCompanyProfile />} />
            <Route path={ UPDATE_JOB_POST } element={<UpdateJobForm />} />

            <Route path={ LIST_OF_JOBS_PER_COMPANY } element={<ListOfJobsPerCompany />} />
            <Route path={ MY_JOBS } element={<UserJobsList  currentPage={currentJobPage}/>} />
            <Route path={ APPROVAL } element={<Approval />} />
        </Routes>
    )
}

export default CustomRoutes;