export const HOME = "/";

// Job Routes
export const JOBS_FEED = "/jobs";
export const NEW_JOB = "/new/job";
export const VIEW_JOB = "/job/:id";
export const UPDATE_JOB_POST = "/update/job/:id";
export const LIST_OF_JOBS_PER_COMPANY = "listing/company/:id";
export const MY_JOBS = "/my-jobs";

// Company Routes
export const COMPANIES_FEED = "/companies";
export const NEW_COMPANY = "/new/company";
export const VIEW_COMPANY_PROFILE = "/company/:id"
export const UPDATE_COMPANY_PROFILE = "/update/company/:id";
export const APPROVAL = "/companies/approve";







