import axios from "axios";

class companyDataService {
    async getAllCompanies(page = 0) {
        try {
            const response = await axios.get(`https://localtalent-backend.onrender.com/companies/all?page=${page}`);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async getUserCompanies(uid) {
        try {
            const response = await axios.post(`https://localtalent-backend.onrender.com/companies/my-company`, uid);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async fetchCompanyById(id) {
        try {
            const response = await axios.get(`https://localtalent-backend.onrender.com/companies/company/${id}`);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async createCompany(form) {
        try {
            await axios.post(`https://localtalent-backend.onrender.com/companies/new/company`, form);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async updateCompany(id, form) {
        try {
            await axios.put(`https://localtalent-backend.onrender.com/companies/update/company/${id}`, form);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async deleteCompany(id) {
        try {
            await axios.delete(`https://localtalent-backend.onrender.com/companies/delete/company/${id}`);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async validateCompanyProdileExistsForUser(uid) {
        try {
            const companyProfileExist = await axios.post('https://localtalent-backend.onrender.com/companies/validate', uid);
            return companyProfileExist;
        } catch (error) {
            console.error("An error occurred while checking if company profile exists for user: " + error);
        }
    }

    async requestJoiningCompanies({ company_id, company_name, company_email, user_info, uid }) {
        try {
            const joinCompany = await axios.post('https://localtalent-backend.onrender.com/companies/join-request', {
                company_id,
                company_name,
                company_email,
                user_info,
                uid
            });
            return joinCompany;
        } catch (error) {
            console.error("An error occurred requesting to join company: " + error);
        }
    }

    async joinCompanyApproval(company_id, uid, action) {
        try {
            const joinCompany = await axios.get(`https://localtalent-backend.onrender.com/companies/approve?company_id=${company_id}&uid=${uid}&action=${action}`);
            return joinCompany;
        } catch (error) {
            console.error("An error occurred requesting to join company: " + error);
            throw error; // Re-throw the error so it can be handled by the calling function
        }
    }
}

export default new companyDataService();